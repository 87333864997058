<template>
  <v-container
    class="d-flex flex-column align-center justify-center text-center"
    style="height: 100vh;"
  >
    <v-card elevation="10" class="pa-6" max-width="450">
      <v-card-title class="headline font-weight-bold justify-center">
        WizFit Login with Google
      </v-card-title>

      <v-card-text class="my-4">
        <v-img
          src="@/assets/Auth/wizfirLogo.svg"
          contain
          max-width="120"
          class="mb-4 mx-auto"
        />
        <p>Securely log in using your Google account.</p>

        <!-- Google Sign-In Button Placeholder -->
        <div
          id="g_id_onload"
          :data-client_id="clientId"
          data-callback="handleCredentialResponse"
          data-auto_prompt="false"
        ></div>
        <div class="g_id_signin" data-type="standard" data-size="large"></div>
      </v-card-text>

      <v-card-actions class="justify-center mt-4">
        <v-btn text color="red" @click="goBack()">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      clientId:
        "170558081662-355ok94bmohn6u4gm9bh47cvjob0k6rk.apps.googleusercontent.com",
    };
  },
  methods: {
    goBack()
     { 
      window.close();
    },
  },
  mounted() {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    // Expose globally
    window.handleCredentialResponse = (response) => {
  console.log("🔐 Google ID Token:", response.credential);

  if (window.opener) {
    window.opener.postMessage(
      {
        token: response.credential,
        source: "google-login",
      },
      "*"
    );
    window.close();
  } else {
    console.warn("❌ window.opener is null. Can't postMessage.");
  }
};

  },
};
</script>
<style scoped>
/* Optional: Additional styling to improve appearance */
#g_id_onload,
.g_id_signin {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
</style>